import { FormGroup } from "@angular/forms";
import { DateFormatYearMonthDayPipe } from '../../../../core/pipes/date-format-year-month-day.pipe'
import { LifeCycleStatusDetailsDTO, convertResponseToLifeCycleStatusDetailsDTO } from "../../../settings/masters/dto/life-cycle-status.dto";
import { SlaCategoryDetailsDTO, convertResponseToSlaCategoryDetailsDTO } from "../../../settings/compliance/dto/sla-category.dto";
import { SeverityLevelDetailsDTO, convertResponseToSeverityLevelDetailsDTO } from "../../../settings/masters/dto/severity-level.dto";
import { OrganizationUnitDetailsDTO } from "../../../settings/general-settings/dto/organization-unit.dto";
import { UserMinimalDTO, convertResponseToUserMinimalDTO } from "../../../organization/users/dto/user.dto";

export class SlaContractDTO {
    id: number;
    referenceNumber: string;
    titleEn: string;
    titleAr: string;
    description: string;
    slaCategoryTitleEn: string;
    slaCategoryTitleAr: string;
    severityLevelTitleEn: string;
    severityLevelTitleAr: string;
    responsibleUnitTitleEn: string;
    responsibleUnitTitleAr: string;
    responsibleUnitFirstNameEn: string;
    responsibleUnitFirstNameAr: string;
    responsibleUnitLastNameEn: string;
    responsibleUnitLastNameAr: string;
    complianceStatusTitleEn: string;
    complianceStatusTitleAr: string;
    complianceStatusColorCode: string;
    lifecycleStatusTitleEn: string;
    lifecycleStatusTitleAr: string;
    lifecycleStatusColorCode: string;
    client: string;
    version: string;
    comment: string;
    issueDate: string;
    expiryDate: string;
    sa1Date: string;
    sa2Date: string;
  }

export interface SlaContractFormDTO {

    id?: number;
    reference_number?: string ;
    title_en: string;
    title_ar: string;
    description: string;
    sla_category_id: number;
    lifecycle_status_id: number;
    severity_level_id: number;
    client: string ;
    version: string ;
    issue_date: string;
    expiry_date: string;
    responsible_unit_id: number;
    responsible_user_id: number;
    sa1_date: string;
    sa2_date: string;
    document :SlaContractDocumentDTO;
}

export interface SlaContractDocumentDTO{
    id?: number;
    file_name: string;
    original_name: string;
    type: string;
    size: number;
    is_deleted_file?: boolean;
    is_new_file?: boolean;
}

export interface SlaContractDocumentDetailsDTO {
    id: number;
    file_name: string;
    original_name: string;
    url: string;
    type: string;
    size: number;
    language: string;
}

export interface SlaContractDetailsDTO {
    id: number;
    reference_number: string;
    title: string;
    description: string;
    client: string;
    version: string;
    issue_date: string;
    expiry_date: string;
    sa1_date: string;
    sa2_date: string;
    created_at: string;
    updated_at: string | null;
    sla_category: SlaCategoryDetailsDTO;
    severity_level: SeverityLevelDetailsDTO | null;
    responsible_unit: OrganizationUnitDetailsDTO;
    responsible_user: UserMinimalDTO;
    status: LifeCycleStatusDetailsDTO;
    document: SlaContractDocumentDetailsDTO;
    created_by: UserMinimalDTO;
    updated_by: UserMinimalDTO | null;
}

export function convertFormToSlaContractFormDTO(formGroup: FormGroup): SlaContractFormDTO {
    return {
        id: formGroup.get('id')?.value,
        sla_category_id: formGroup.get('Category')?.value,
        lifecycle_status_id: parseInt(formGroup.get('LifecycleStatus')?.value, 10),
        title_en: formGroup.get('Title')?.value,
        title_ar: formGroup.get('Title')?.value,
        description: formGroup.get('Description')?.value,
        severity_level_id: parseInt(formGroup.get('SeverityLevel')?.value, 10),
        client: formGroup.get('Client')?.value,
        version: formGroup.get('Version')?.value,
        // responsible_unit_id: formGroup.get('ResponsibleUnit')?.value,
        responsible_unit_id: 1, // TODO Data should be taken from form
        responsible_user_id: formGroup.get('ResponsibleUser')?.value,
        issue_date: formGroup.get('IssueDate')?.value,
        expiry_date: formGroup.get('ExpiryDate')?.value,
        sa1_date: formGroup.get('SA1Date')?.value,
        sa2_date: formGroup.get('SA2Date')?.value,
        document: formGroup.get('Document')?.value
    };
}

export function convertResponseToForm(response: any): any {
    const dateFormatPipe = new DateFormatYearMonthDayPipe();

    return {
        id : response.id,
        ReferenceNumber : response.reference_number,
        Category : response.sla_category?.id,
        LifecycleStatus : response.lifecycle_status?.id,
        Title : response.title_en,
        // TitleAr : response.title_ar,
        Description : response.description,
        ResponsibleUnit : response.responsible_unit?.id,
        ResponsibleUser : response.responsible_user?.id,
        SeverityLevel : response.severity_level?.id,
        Client : response.client,
        Version : response.documents[0].version,
        IssueDate : dateFormatPipe.transform(response.documents[0].issue_date),
        ExpiryDate : dateFormatPipe.transform(response.documents[0].expiry_date),
        SA1Date : dateFormatPipe.transform(response.documents[0].sa1_date),
        SA2Date : dateFormatPipe.transform(response.documents[0].sa2_date),
        Document:response.documents[0],
    }
}

export function convertResponseToSlaContractDetailsDTO(
    response: any,
    lang: string
): SlaContractDetailsDTO {
    const getLocalizedField = (field: string) => {
        return response[`${field}_${lang}`] || response[`${field}_en`];
    };

    return {
        id: response.id,
        reference_number: response.reference_number,
        title: getLocalizedField('title'),
        description: response.description,
        client: response.client,
        version: response.version,
        issue_date: response.issue_date,
        expiry_date: response.expiry_date,
        sa1_date: response.sa1_date,
        sa2_date: response.sa2_date,
        created_at: response.created_at,
        updated_at: response.updated_at ? response.updated_at : null,
        sla_category: convertResponseToSlaCategoryDetailsDTO(
            response.sla_category,
            lang
        ),
        severity_level: convertResponseToSeverityLevelDetailsDTO(
            response.severity_level,
            lang
        ),
        responsible_unit: {
            id: response.responsible_unit.id,
            title: response.responsible_unit.title_en,
            status: response.responsible_unit.status,
        },
        responsible_user: convertResponseToUserMinimalDTO(response.responsible_user, lang),
        status: convertResponseToLifeCycleStatusDetailsDTO(response.lifecycle_status, lang),
        document: response.documents[0],
        created_by: convertResponseToUserMinimalDTO(response.created_by, lang),
        updated_by: response.updated_by
            ? convertResponseToUserMinimalDTO(response.updated_by, lang)
            : null,
    };
  }
