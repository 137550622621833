import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatYearMonthDay',
  standalone: true
})
export class DateFormatYearMonthDayPipe implements PipeTransform {

  transform(value: string): string  {
      if (!value) return '';
      const date = new Date(value);
      return date.toISOString().slice(0, 10); // Get the YYYY-MM-DD part
  }

}
