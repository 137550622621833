export interface SlaCategoryDTO {
    id: number;
    title_en: string;
    title_ar: string;
    status: string;
}

export interface SlaCategoryFormDTO {
    id?: number;
    title_en: string;
    title_ar: string;
    status: string;
}

export interface SlaCategoryDetailsDTO {
    id: number;
    title: string;
    status: string;
}

export function convertResponseToSlaCategoryDetailsDTO(
    response: any,
    lang: string
  ): SlaCategoryDetailsDTO {
    return {
      id: response.id,
      title: response[`title_${lang}`],
      status: response.status,
    };
  }