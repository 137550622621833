import { Injectable } from '@angular/core';
import { BaseService } from '../../../../core/services/base.service';
import { SlaContractDTO, SlaContractDetailsDTO, SlaContractFormDTO } from '../dto/sla-contract.dto';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SlaContractService extends BaseService<SlaContractDTO, SlaContractDetailsDTO, SlaContractFormDTO> {
  protected apiUrl = environment.apiUrl + '/sla-register';

  constructor(http: HttpClient) {
    super(http);
  }
}
